/* eslint-disable jsx-a11y/alt-text */
import {
  Avatar,
  AvatarBadge,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  IconButton,
  Image,
  useColorMode,
  useDisclosure,
  Text,
  Popover,
  PopoverTrigger as OrigPopoverTrigger,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  ButtonGroup,
  Button,
  AccordionItem,
  Accordion,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  Box,
  DrawerFooter,
  Switch,
  Link,
  Tooltip,
  useMediaQuery,
  FormControl,
  FormLabel,
  Alert,
  AlertIcon,
  Collapse,
  HStack,
  Select,
  PopoverAnchor,
  Menu,
  MenuButton,
  Portal,
  Icon,
  DrawerBody,
  VStack,
  AspectRatio,
  Divider,
  SimpleGrid,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import {
  AddressBook,
  ArrowsClockwise,
  Bell,
  CalendarCheck,
  ChartBar,
  CloudArrowUp,
  CurrencyDollar,
  Files,
  FileText,
  Gear,
  GraduationCap,
  Handshake,
  IdentificationBadge,
  LinkSimple,
  List,
  MoonStars,
  QrCode,
  Question,
  SignOut,
  Storefront,
  Sun,
  User,
  Link as LinkIcon,
  UserGear,
  YoutubeLogo,
  Newspaper,
  Upload,
  Browsers,
} from "phosphor-react";
import { Fragment, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import {
  currentUserDataState,
  graphTimePeriodState,
  graphTypeState,
  isEmployeeState,
  isLoggedInState,
  totalGraphDataState,
  useIsLoggedIn,
  xAxisLabelState,
  yAxisLabelState,
  totalOrdersDataState,
  notificationAmountState,
} from "../recoilStates";
import useSound from "use-sound";
import clickSFX from "../assets/sounds/click.mp3";
import { NextUIProvider } from "@nextui-org/react";

import axios from "axios";
import OrderNotifications from "./OrderNotifications";
import { MotionFlex } from "./CartItem";
import { LoadingOverlay } from "@mantine/core";
import { props } from "cypress/types/bluebird";
import NextLink from "next/link";

export const PopoverTrigger: React.FC<{ children: React.ReactNode }> =
  OrigPopoverTrigger;

const DashboardNav = (props: any) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: popoverOpened,
    onOpen: openNotifPopover,
    onClose: closePopover,
    onToggle: togglePopover,
  } = useDisclosure();
  const {
    isOpen: tutorialsOpened,
    onOpen: openTutorials,
    onClose: closeTutorials,
    onToggle: toggleTutorials,
  } = useDisclosure();

  // const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
  const [currentUserData, setCurrentUserData] =
    useRecoilState(currentUserDataState);
  const router = useRouter();
  const [isLoggedIn, setIsLoggedIn] = useIsLoggedIn();
  const [playbackRate, setPlaybackRate] = useState(1);
  const [orderNotifTypeValue, setOrderNotifTypeValue] = useState(1);
  const [emailNotifsOn, setEmailNotifsOn] = useState(false);
  const [playClick] = useSound(clickSFX, { playbackRate });
  const [totalGraphData, setTotalGraphData] =
    useRecoilState(totalGraphDataState);
  const [totalOrderData, setTotalOrderData] =
    useRecoilState(totalOrdersDataState);
  const [isEmployee, setIsEmployee] = useRecoilState(isEmployeeState);
  const MotionAlert = motion(Alert);
  const popoverFocusRef = useRef<any>();
  const [isPhone, setIsPhone] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState(1);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [notificationAmount, setNotificationAmount] = useRecoilState(
    notificationAmountState
  );
  const mq = useMediaQuery("(max-width: 800px)");
  useEffect(() => {
    setIsPhone(mq[0]);
    setEmailNotifsOn(currentUserData.SendOrderNotificationEmail);
  }, [mq, currentUserData]);

  return (
    <Flex
      // position="fixed"
      height="100px"
      bg={colorMode == "dark" ? "econic.900" : "#FFF"}
      padding={isPhone ? "2% 5%" : "2% 3%"}
      shadow="lg"
      // align="center"
      gap="20px"
      width="100%"
      alignItems="center"
      zIndex={100}
      display={
        isLoggedIn &&
        !router.pathname.includes("/sign-in") &&
        !router.pathname.includes("/customer-device-success")
          ? "flex"
          : "none"
      }
    >
      <MotionAlert
        status="error"
        borderRadius="lg"
        position="fixed"
        initial={{ width: 175 }}
        whileHover={{ width: 175 }}
        animate={{ width: isPhone ? 175 : 45 }}
        transition={{ bounce: 0 }}
        pl="3"
        left="0vw"
        top="90vh"
        variant="solid"
        zIndex={100}
        display={isEmployee ? "flex" : "none"}
      >
        <AlertIcon></AlertIcon>
        <Text whiteSpace="nowrap" fontWeight="bold">
          Employee Mode
        </Text>
      </MotionAlert>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        blockScrollOnMount={true}
        closeOnOverlayClick={true}
      >
        <DrawerContent
          overflowY="auto"
          bg={colorMode === "dark" ? "brand.900" : "#FFF"}
          padding="0% 5px"
          overflowX="hidden"
        >
          <DrawerHeader>
            {totalGraphData?.Summary?.BusinessName || "Your Business"}
          </DrawerHeader>
          <DrawerCloseButton></DrawerCloseButton>
          <MenuAccordian onClose={onClose} closeDrawer={onClose} />

          <Button
            colorScheme="red"
            bg={colorMode == "dark" ? "red.600" : "red.500"}
            color="white"
            mt="10%"
            width="fit-content"
            alignSelf="center"
            minH="45px"
            size="lg"
            gap={2}
            onClick={() => {
              setIsLoggedIn(false);
              router.push("/sign-in");
              // setCurrentUserData({});
              // setTotalGraphData({});
              localStorage.clear();
              onClose();
              setIsEmployee(false);
            }}
          >
            <SignOut></SignOut>
            <Text>LOG OUT</Text>
          </Button>
          <DrawerFooter mt="auto" width="full">
            <Flex
              alignItems="center"
              justifyItems="center"
              textAlign="center"
              gap="2%"
            >
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="email-alerts" mb="0">
                  Enable email alerts?
                </FormLabel>
                <Switch
                  id="email-alerts"
                  // color="brand.orange"
                  colorScheme="brand"
                  // isChecked={emailNotifsOn}
                  isChecked={emailNotifsOn}
                  onChange={async () => {
                    await axios
                      .put("/api/setEmailNotifications", {
                        personId: currentUserData.PersonId,
                        emailNotifsOn: !emailNotifsOn,
                      })
                      .then(() => setEmailNotifsOn(!emailNotifsOn));
                  }}
                />
              </FormControl>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <IconButton
        aria-label="Open Menu"
        icon={<List size={30}></List>}
        size="lg"
        onClick={onOpen}
      ></IconButton>
      <Popover placement="bottom-end" matchWidth={true} closeOnBlur={true}>
        <PopoverTrigger>
          <Avatar
            icon={
              <Bell
                size={30}
                color={colorMode == "dark" ? "#4264af" : "#4264af"}
                weight="duotone"
                cursor="pointer"
              ></Bell>
            }
            bg="none"
          >
            <AvatarBadge
              boxSize="1.25em"
              bg="brand.orange"
              display={notificationAmount >= 1 ? "flex" : "none"}
            >
              <Text fontSize={10} color="white" fontWeight="extrabold">
                {notificationAmount}
              </Text>
            </AvatarBadge>
          </Avatar>
        </PopoverTrigger>
        <PopoverContent
          // bg={colorMode == "dark" ? "brand.900" : "#FFF"}
          width="400px"
          maxH="90vh"
          maxW="100vw"
        >
          <PopoverHeader>
            <Flex flexDir="column">
              <Flex gap="5%" alignItems="center">
                <IconButton
                  aria-label="Settings"
                  icon={<Gear size={20}></Gear>}
                  onClick={() => setSettingsOpen(!settingsOpen)}
                ></IconButton>
                <ButtonGroup
                  isAttached
                  size="md"
                  ref={popoverFocusRef}
                  color="white"
                >
                  <Button
                    colorScheme={
                      orderNotifTypeValue == 1 ? "red" : "blackAlpha"
                    }
                    color="white"
                    onClick={() => {
                      setOrderNotifTypeValue(1);
                    }}
                  >
                    New
                  </Button>
                  <Button
                    colorScheme={
                      orderNotifTypeValue == 2 ? "yellow" : "blackAlpha"
                    }
                    color="white"
                    onClick={() => setOrderNotifTypeValue(2)}
                  >
                    Pending
                  </Button>
                  <Button
                    color="white"
                    onClick={() => setOrderNotifTypeValue(3)}
                    colorScheme={
                      orderNotifTypeValue == 3 ? "green" : "blackAlpha"
                    }
                  >
                    Done
                  </Button>
                </ButtonGroup>
                <PopoverCloseButton size="lg"></PopoverCloseButton>
              </Flex>
              <Collapse in={settingsOpen}>
                <Flex p="4" pl="0" alignItems="center" gap="5">
                  <Text>Refresh every:</Text>
                  <Select
                    width="60%"
                    focusBorderColor="brand.500"
                    size="sm"
                    value={refreshInterval}
                    onChange={(e: any) => setRefreshInterval(e.target.value)}
                  >
                    <option value={1}>1 Minute</option>
                    <option value={5}>5 Minutes</option>
                    <option value={10}>10 Minutes</option>
                    <option value={15}>15 Minutes</option>
                    <option value={30}>30 Minutes</option>
                    <option value={60}>1 Hour</option>
                  </Select>
                </Flex>
              </Collapse>
            </Flex>
          </PopoverHeader>
          <PopoverBody
            minH="400px"
            height="fit-content"
            overflowY="auto"
            width="400px"
            maxW="100vw"
            maxH="75vh"
          >
            <OrderNotifications
              orderNotifTypeValue={orderNotifTypeValue}
              openNotifPopover={openNotifPopover}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <NextLink href="/">
        <Image
          src={
            colorMode == "dark"
              ? "/blueWhiteEconic.svg"
              : "/blueBlackEconic.svg"
          }
          w={32}
          left="50%"
          transform={`translateX(-50%)`}
          cursor="pointer"
          position="absolute"
        ></Image>
      </NextLink>
      <IconButton
        marginLeft="auto"
        size="lg"
        aria-label="tutorials"
        mr="2"
        variant="ghost"
        onClick={toggleTutorials}
      >
        <GraduationCap size={30} weight="fill" />
      </IconButton>
      <Tooltip label="Change Theme">
        <IconButton
          size="lg"
          onClick={() => {
            colorMode == "dark" ? setPlaybackRate(1.1) : setPlaybackRate(0.75);
            toggleColorMode();
            playClick();
          }}
          aria-label="change Theme"
          icon={
            colorMode == "dark" ? (
              <MoonStars size={30} weight="fill" />
            ) : (
              <Sun size={30} weight="fill" />
            )
          }
        />
      </Tooltip>
      <Drawer
        preserveScrollBarGap={true}
        isOpen={tutorialsOpened}
        size="xl"
        onClose={closeTutorials}
      >
        <DrawerOverlay backdropFilter="blur(12px)" />
        <DrawerContent bg={colorMode == "dark" ? "econic.1000" : "#FFF"}>
          <DrawerCloseButton size="lg" />

          <DrawerBody
            css={{
              "&::-webkit-scrollbar": {
                direction: "rtl",
                width: 4,
              },
              direction: "rtl",
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#4264af",
              },
            }}
          >
            <HStack
              mb="5"
              w="full"
              spacing={3}
              justify="center"
              css={{ direction: "ltr" }}
            >
              <Image
                w={12}
                h={12}
                src={
                  colorMode == "dark" ? "/newEDark.svg" : "/logoOnlyLight.svg"
                }
              />
              <Heading size="2xl">Education</Heading>
            </HStack>
            <Box minW="full" minH="0.1px" bgColor="white"></Box>
            <Flex
              direction="column"
              css={{ direction: "ltr" }}
              mt="10"
              px={[0, null, 5]}
              gap="5"
              h="full"
            >
              <Flex align="center" gap="4">
                <YoutubeLogo size={45} weight="fill" />
                <Heading>Videos</Heading>
              </Flex>
              <SimpleGrid columns={[1, null, 2]} spacingX={16} spacingY={10}>
                {tutorialVideos.map((video, index) => (
                  <AspectRatio
                    ratio={6 / 3}
                    borderRadius="lg"
                    key={index}
                    overflow="hidden"
                    shadow="dark-lg"
                  >
                    <iframe
                      src={video}
                      allow="picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </AspectRatio>
                ))}
              </SimpleGrid>
              <Flex align="center" gap="4" mt="10">
                <Newspaper size={45} weight="fill" />
                <Heading alignSelf="flex-start">Articles</Heading>
              </Flex>

              <VStack>
                {tutorialLinks.map((link, index) => (
                  <TutorialItem linkDetails={link} key={index} />
                ))}
              </VStack>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};
export default DashboardNav;

const TutorialItem = (props: { linkDetails: any }) => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <MotionFlex
      initial={{ backgroundColor: "rgba(0,0,0,0)" }}
      minW="full"
      whileHover={{
        backgroundColor:
          colorMode == "dark" ? "rgba(255,255,255,0.25)" : "rgba(0,0,0,0.1)",
      }}
      cursor="pointer"
      as={Link}
      p="3"
      px="5"
      target="_blank"
      href={props.linkDetails.url}
      align="center"
      borderRadius="2xl"
    >
      <Text fontSize={25}>{props.linkDetails.title}</Text>
      <Box ml="auto">
        <LinkIcon size={40} weight="fill" />
      </Box>
    </MotionFlex>
  );
};
const MenuAccordian = (props: any) => {
  const router = useRouter();
  const [timePeriod, setTimePeriod] = useRecoilState(graphTimePeriodState);
  const [xAxisLabel, setXAxisLabel] = useRecoilState(xAxisLabelState);
  const [graphType, setGraphType] = useRecoilState(graphTypeState);

  const [isEmployee, setIsEmployee] = useRecoilState(isEmployeeState);
  const [isEmployeeuseState, setIsEmployeeUseState] = useState(isEmployee);
  useEffect(() => {
    setIsEmployeeUseState(isEmployee);
  }, [isEmployee]);

  const [currentUserData, setCurrentUserData] =
    useRecoilState(currentUserDataState);
  const { colorMode, toggleColorMode } = useColorMode();

  const [yAxisLabel, setYAxisLabel] = useRecoilState(yAxisLabelState);

  const defaultIndex = router.pathname.includes("/dashboard")
    ? 0
    : router.pathname.includes("/register")
    ? 1
    : router.pathname.includes("/customer-list")
    ? 2
    : router.pathname.includes("/order-list")
    ? 3
    : 4;
  return (
    <Accordion allowToggle defaultIndex={[defaultIndex]}>
      <AccordionItem display={isEmployeeuseState ? "none" : ""}>
        <h2>
          <AccordionButton
            onClick={() =>
              router.pathname.includes("/dashboard")
                ? null
                : router.push(
                    `/dashboard/${currentUserData.GUID}/?businessId=${currentUserData.PersonId}`
                  )
            }
            bg={router.pathname.includes("/dashboard") ? "brand.500" : ""}
            _hover={{ bgColor: "brand.400" }}
            borderRadius="lg"
          >
            <Flex flex="1" textAlign="left" alignItems="center" gap={3}>
              <ChartBar size={40} weight="duotone"></ChartBar>
              <Text>Dashboard</Text>
            </Flex>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} pl={10}>
          <Accordion
            allowToggle
            defaultIndex={[
              graphType == "revenue"
                ? 0
                : graphType == "transactions"
                ? 1
                : graphType == "customers"
                ? 2
                : 90,
            ]}
          >
            <AccordionItem>
              <h2>
                <AccordionButton
                  borderRadius="lg"
                  onClick={() => {
                    // router.push("/");
                    setTimePeriod("today");
                    setGraphType("revenue");
                    setYAxisLabel("Revenue");
                    setXAxisLabel("Time");
                  }}
                  //   bg={router.pathname == "/" ? "brand.500" : ""}
                  _hover={{ bgColor: "brand.400" }}
                  //   borderRadius="lg"
                >
                  <Flex flex="1" textAlign="left" alignItems="center" gap={3}>
                    <CurrencyDollar size={30}></CurrencyDollar>
                    <Text>Revenue</Text>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Flex flexDir="column" alignItems="center" gap="1">
                  <Button
                    bg={timePeriod == "today" ? "brand.500" : "none"}
                    size="sm"
                    _hover={{ bgColor: "brand.400" }}
                    colorScheme="brand"
                    color={timePeriod == "today" ? "white" : ""}
                    onClick={() => {
                      setTimePeriod("today");
                      setXAxisLabel("Time");
                    }}
                  >
                    Today
                  </Button>
                  <Button
                    bg={timePeriod == "week" ? "brand.500" : "none"}
                    size="sm"
                    colorScheme="brand"
                    _hover={{ bgColor: "brand.400" }}
                    color={timePeriod == "week" ? "white" : ""}
                    onClick={() => {
                      setTimePeriod("week");
                      setXAxisLabel("Day");
                    }}
                  >
                    Week
                  </Button>
                  <Button
                    bg={timePeriod == "month" ? "brand.500" : "none"}
                    size="sm"
                    colorScheme="brand"
                    _hover={{ bgColor: "brand.400" }}
                    color={timePeriod == "month" ? "white" : ""}
                    onClick={() => {
                      setTimePeriod("month");
                      setXAxisLabel("Month");
                    }}
                  >
                    Month
                  </Button>
                  <Button
                    bg={timePeriod == "quarter" ? "brand.500" : "none"}
                    size="sm"
                    _hover={{ bgColor: "brand.400" }}
                    colorScheme="brand"
                    color={timePeriod == "quarter" ? "white" : ""}
                    onClick={() => {
                      setTimePeriod("quarter");
                      setXAxisLabel("Quarter");
                    }}
                  >
                    Quarter
                  </Button>
                  <Button
                    bg={timePeriod == "year" ? "brand.500" : "none"}
                    size="sm"
                    colorScheme="brand"
                    _hover={{ bgColor: "brand.400" }}
                    color={timePeriod == "year" ? "white" : ""}
                    onClick={() => {
                      setTimePeriod("year");
                      setXAxisLabel("Month of the year");
                    }}
                  >
                    Year
                  </Button>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton
                  borderRadius="lg"
                  onClick={() => {
                    // router.push("/");
                    setTimePeriod("today");
                    setGraphType("transactions");
                    setYAxisLabel("Transactions");
                    setXAxisLabel("Time");
                  }}
                  _hover={{ bgColor: "brand.400" }}
                >
                  <Flex flex="1" textAlign="left" alignItems="center" gap={3}>
                    <Handshake size={30} weight="duotone"></Handshake>
                    <Text>Transactions</Text>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Flex flexDir="column" alignItems="center" gap="1">
                  <Button
                    bg={timePeriod == "today" ? "brand.500" : "none"}
                    size="sm"
                    colorScheme="brand"
                    _hover={{ bgColor: "brand.400" }}
                    color={timePeriod == "today" ? "white" : ""}
                    onClick={() => {
                      setTimePeriod("today");
                      setXAxisLabel("Time");
                    }}
                  >
                    Today
                  </Button>
                  <Button
                    bg={timePeriod == "week" ? "brand.500" : "none"}
                    size="sm"
                    colorScheme="brand"
                    _hover={{ bgColor: "brand.400" }}
                    color={timePeriod == "week" ? "white" : ""}
                    onClick={() => {
                      setTimePeriod("week");
                      setXAxisLabel("Day");
                    }}
                  >
                    Week
                  </Button>
                  <Button
                    bg={timePeriod == "month" ? "brand.500" : "none"}
                    size="sm"
                    colorScheme="brand"
                    _hover={{ bgColor: "brand.400" }}
                    color={timePeriod == "month" ? "white" : ""}
                    onClick={() => {
                      setTimePeriod("month");
                      setXAxisLabel("Month");
                    }}
                  >
                    Month
                  </Button>
                  <Button
                    bg={timePeriod == "quarter" ? "brand.500" : "none"}
                    size="sm"
                    _hover={{ bgColor: "brand.400" }}
                    colorScheme="brand"
                    color={timePeriod == "quarter" ? "white" : ""}
                    onClick={() => {
                      setTimePeriod("quarter");
                      setXAxisLabel("Quarter");
                    }}
                  >
                    Quarter
                  </Button>
                  <Button
                    bg={timePeriod == "year" ? "brand.500" : "none"}
                    size="sm"
                    _hover={{ bgColor: "brand.400" }}
                    colorScheme="brand"
                    color={timePeriod == "year" ? "white" : ""}
                    onClick={() => {
                      setTimePeriod("year");
                      setXAxisLabel("Month of the year");
                    }}
                  >
                    Year
                  </Button>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton
                  borderRadius="lg"
                  onClick={() => {
                    // router.push("/");

                    setTimePeriod("week");
                    setGraphType("customers");
                    setYAxisLabel("Customers");
                    setXAxisLabel("Time");
                  }}
                  //   bg={router.pathname == "/" ? "brand.500" : ""}
                  _hover={{ bgColor: "brand.400" }}
                >
                  <Flex flex="1" textAlign="left" alignItems="center" gap={3}>
                    <User size={30} weight="duotone"></User>
                    <Text>Customers</Text>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Flex flexDir="column" alignItems="center" gap="1">
                  <Button
                    bg={timePeriod == "week" ? "brand.500" : "none"}
                    size="sm"
                    colorScheme="brand"
                    _hover={{ bgColor: "brand.400" }}
                    color={timePeriod == "week" ? "white" : ""}
                    onClick={() => {
                      setTimePeriod("week");
                      setXAxisLabel("Day");
                    }}
                  >
                    Week
                  </Button>
                  <Button
                    bg={timePeriod == "month" ? "brand.500" : "none"}
                    size="sm"
                    colorScheme="brand"
                    _hover={{ bgColor: "brand.400" }}
                    color={timePeriod == "month" ? "white" : ""}
                    onClick={() => {
                      setTimePeriod("month");
                      setXAxisLabel("Month");
                    }}
                  >
                    Month
                  </Button>
                  <Button
                    bg={timePeriod == "quarter" ? "brand.500" : "none"}
                    size="sm"
                    colorScheme="brand"
                    _hover={{ bgColor: "brand.400" }}
                    color={timePeriod == "quarter" ? "white" : ""}
                    onClick={() => {
                      setTimePeriod("quarter");
                      setXAxisLabel("Quarter");
                    }}
                  >
                    Quarter
                  </Button>
                  <Button
                    bg={timePeriod == "year" ? "brand.500" : "none"}
                    size="sm"
                    colorScheme="brand"
                    _hover={{ bgColor: "brand.400" }}
                    color={timePeriod == "year" ? "white" : ""}
                    onClick={() => {
                      setTimePeriod("year");
                      setXAxisLabel("Month of the year");
                    }}
                  >
                    Year
                  </Button>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <motion.div
            whileHover={{ translateX: 30 }}
            animate={{
              translateX: router.pathname.includes("/register") ? 30 : 0,
            }}
          >
            <AccordionButton
              _hover={{ bgColor: "brand.400" }}
              borderRadius="lg"
              onClick={() =>
                router.push(`/register?businessId=${currentUserData.PersonId}`)
              }
              bg={router.pathname.includes("/register") ? "brand.500" : ""}
            >
              <Flex flex="1" textAlign="left" alignItems="center" gap={3}>
                <Storefront size={40} weight="duotone"></Storefront>
                <Text>Register (POS)</Text>
              </Flex>
            </AccordionButton>
          </motion.div>
        </h2>
      </AccordionItem>
      <AccordionItem display={isEmployeeuseState ? "none" : ""}>
        <h2>
          <motion.div
            whileHover={{ translateX: 30 }}
            animate={{
              translateX: router.pathname.includes("/appointment-calendar")
                ? 30
                : 0,
            }}
          >
            <AccordionButton
              bg={
                router.pathname.includes("/appointment-calendar")
                  ? "brand.500"
                  : ""
              }
              _hover={{ bgColor: "brand.400" }}
              borderRadius="lg"
              onClick={() =>
                router.push(
                  `/appointment-calendar?businessId=${currentUserData.PersonId}`
                )
              }
            >
              <Flex flex="1" textAlign="left" alignItems="center" gap={3}>
                <CalendarCheck size={40} weight="duotone"></CalendarCheck>
                <Text>Calendar</Text>
              </Flex>
            </AccordionButton>
          </motion.div>
        </h2>
      </AccordionItem>
      <AccordionItem display={isEmployeeuseState ? "none" : ""}>
        <h2>
          <motion.div
            whileHover={{ translateX: 30 }}
            animate={{
              translateX: router.pathname.includes("/customer-list") ? 30 : 0,
            }}
          >
            <AccordionButton
              onClick={() => {
                router.push("/customer-list");
              }}
              bg={router.pathname.includes("/customer-list") ? "brand.500" : ""}
              _hover={{ bgColor: "brand.400" }}
              borderRadius="lg"
            >
              <Flex flex="1" textAlign="left" alignItems="center" gap={3}>
                <AddressBook size={40} weight="duotone"></AddressBook>
                <Text>Customer List</Text>
              </Flex>
            </AccordionButton>
          </motion.div>
        </h2>
        {/* <AccordionPanel pb={4}>See all your customers here</AccordionPanel> */}
      </AccordionItem>
      <AccordionItem display={isEmployeeuseState ? "none" : ""}>
        <h2>
          <motion.div
            whileHover={{ translateX: 30 }}
            animate={{
              translateX: router.pathname.includes("/order-list") ? 30 : 0,
            }}
          >
            <AccordionButton
              bg={router.pathname.includes("/order-list") ? "brand.500" : ""}
              onClick={() => router.push("/order-list")}
              _hover={{ bgColor: "brand.400" }}
              borderRadius="lg"
            >
              <Flex flex="1" textAlign="left" alignItems="center" gap={3}>
                <Files size={40} weight="duotone"></Files>
                <Text>Order List</Text>
              </Flex>
              {/* <AccordionIcon /> */}
            </AccordionButton>
          </motion.div>
        </h2>
      </AccordionItem>

      <AccordionItem display={isEmployeeuseState ? "none" : ""}>
        <h2>
          <motion.div
            whileHover={{ translateX: 30 }}
            animate={{
              translateX: router.pathname.includes("/forms") ? 30 : 0,
            }}
          >
            <AccordionButton
              _hover={{ bgColor: "brand.400" }}
              borderRadius="lg"
              bg={router.pathname.includes("/forms") ? "brand.500" : ""}
              onClick={() =>
                router.push(
                  `/forms/forms?businessId=${currentUserData.PersonId}`
                )
              }
            >
              <Flex flex="1" textAlign="left" alignItems="center" gap={3}>
                <FileText size={40}></FileText>
                <Text> Forms </Text>
              </Flex>
            </AccordionButton>
          </motion.div>
        </h2>
      </AccordionItem>

      <AccordionItem display={isEmployeeuseState ? "none" : ""}>
        <h2>
          <motion.div
            whileHover={{ translateX: 30 }}
            animate={{
              translateX: router.pathname.includes("/QR") ? 30 : 0,
            }}
          >
            <AccordionButton
              _hover={{ bgColor: "brand.400" }}
              borderRadius="lg"
              onClick={() => router.push("/QR-code")}
              bg={router.pathname.includes("/QR") ? "brand.500" : ""}
            >
              <Flex flex="1" textAlign="left" alignItems="center" gap={3}>
                <QrCode size={40} weight="duotone"></QrCode>
                <Text>QR Codes</Text>
              </Flex>
            </AccordionButton>
          </motion.div>
        </h2>
      </AccordionItem>
      <AccordionItem display={isEmployeeuseState ? "none" : ""}>
        <h2>
          <motion.div
            whileHover={{ translateX: 30 }}
            animate={{
              translateX: router.pathname.includes("/business-pages") ? 30 : 0,
            }}
          >
            <AccordionButton
              _hover={{ bgColor: "brand.400" }}
              bg={
                router.pathname.includes("/business-pages") ? "brand.500" : ""
              }
              borderRadius="lg"
              onClick={() => router.push("/business-pages")}
            >
              <Flex flex="1" textAlign="left" alignItems="center" gap={3}>
                <Browsers size={40} weight="duotone"></Browsers>
                <Text>Your Pages</Text>
              </Flex>
            </AccordionButton>
          </motion.div>
        </h2>
      </AccordionItem>

      <AccordionItem display={isEmployeeuseState ? "none" : ""}>
        <h2>
          <motion.div
            whileHover={{ translateX: 30 }}
            animate={{
              translateX: router.pathname.includes("/employee") ? 30 : 0,
            }}
          >
            <AccordionButton
              _hover={{ bgColor: "brand.400" }}
              borderRadius="lg"
              bg={router.pathname.includes("/employee-pin") ? "brand.500" : ""}
              onClick={() => router.push("/employee-pin-change")}
            >
              <Flex flex="1" textAlign="left" alignItems="center" gap={3}>
                <IdentificationBadge
                  size={40}
                  weight="duotone"
                ></IdentificationBadge>
                <Text>Employee PIN</Text>
              </Flex>
            </AccordionButton>
          </motion.div>
        </h2>
      </AccordionItem>
      <AccordionItem display={isEmployeeuseState ? "none" : ""}>
        <h2>
          <motion.div
            whileHover={{ translateX: 30 }}
            animate={{
              translateX: router.pathname.includes("/change-main") ? 30 : 0,
            }}
          >
            <AccordionButton
              bg={router.pathname.includes("/change-main") ? "brand.500" : ""}
              _hover={{ bgColor: "brand.400" }}
              borderRadius="lg"
              onClick={() => router.push("/change-main-pin")}
            >
              <Flex flex="1" textAlign="left" alignItems="center" gap={3}>
                <UserGear size={40} weight="duotone"></UserGear>
                <Text>Reset Main PIN</Text>
              </Flex>
            </AccordionButton>
          </motion.div>
        </h2>
      </AccordionItem>
      <AccordionItem display={isEmployeeuseState ? "none" : ""}>
        <h2>
          <motion.div
            whileHover={{ translateX: 30 }}
            animate={{
              translateX: router.pathname.includes("/upload-files") ? 30 : 0,
            }}
          >
            <AccordionButton
              _hover={{ bgColor: "brand.400" }}
              borderRadius="lg"
              bg={router.pathname.includes("/upload-files") ? "brand.500" : ""}
              onClick={() =>
                router.push(
                  `/upload-files?businessId=${currentUserData.PersonId}`
                )
              }
            >
              <Flex flex="1" textAlign="left" alignItems="center" gap={3}>
                <CloudArrowUp size={40} weight="duotone"></CloudArrowUp>
                <Text>Upload Files</Text>
              </Flex>
            </AccordionButton>
          </motion.div>
        </h2>
      </AccordionItem>
    </Accordion>
  );
};

const tutorialLinks = [
  {
    url: "https://content.econicnetwork.com/tutorial-topics/your-econic-network-dashboard",
    title: "Your Econic Network dashboard",
  },
  {
    url: "https://content.econicnetwork.com/tutorial-topics/the-online-register-video/",
    title: "Using the Online Register",
  },

  {
    url: "https://content.econicnetwork.com/tutorial-topics/giving-employees-access-to-the-dashboard/",
    title: "Giving employees access to the dashboard",
  },
  {
    url: "https://content.econicnetwork.com/tutorial-topics/managing-your-order-list/",
    title: "Managing your order list",
  },
  {
    url: "https://content.econicnetwork.com/tutorial-topics/managing-your-customer-list/",
    title: "Managing your customer list",
  },
  {
    url: "https://content.econicnetwork.com/tutorial-topics/displaying-your-qr-codes/",
    title: "Display your QR Codes",
  },
  {
    url: "https://content.econicnetwork.com/tutorial-topics/in-store-operations-with-econic/",
    title: "In-Store operations with Econic Network",
  },
  {
    url: "https://content.econicnetwork.com/tutorial-topics/order-notifications-and-customizing-your-workflow/",
    title: "Order notifications and customizing your workflow",
  },
];

const tutorialVideos = [
  "https://www.youtube.com/embed/Pt7gEdcru8Q",
  "https://www.youtube.com/embed/85JUly8bdu0",
];
