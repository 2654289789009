import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
import localForage from "localforage";

const { persistAtom } = recoilPersist({
  key: "recoil-persist",
});

export const yAxisLabelState = atom({
  key: "yAxisLabelState", // unique ID (with respect to other atoms/selectors)
  default: "Revenue", // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
export const xAxisLabelState = atom({
  key: "xAxisLabelState", // unique ID (with respect to other atoms/selectors)
  default: "Time", // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
export const notificationAmountState = atom({
  key: "notificationAmountState", // unique ID (with respect to other atoms/selectors)
  default: 0, // default value (aka initial value)
});
export const graphTimePeriodState = atom({
  key: "graphTimePeriodState", // unique ID (with respect to other atoms/selectors)
  default: "today", // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
export const graphTypeState = atom({
  key: "graphTypeState", // unique ID (with respect to other atoms/selectors)
  default: "revenue", // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
export const isLoggedInState = atom({
  key: "isLoggedInState", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
export const currentUserDataState = atom({
  key: "currentUserDataState", // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
export const totalGraphDataState = atom({
  key: "totalGraphDataState", // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
export const totalLocationDataState = atom({
  key: "totalLocationDataState", // unique ID (with respect to other atoms/selectors)
  default: <any>{}, // default value (aka initial value)
});
export const isEmployeeState = atom({
  key: "isEmployeeState", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
export const checkoutCompleteOrderDataState = atom({
  key: "checkoutCompleteOrderData", // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
export const totalOrdersDataState = atom({
  key: "totalOrdersDataState", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
export const checkoutSessionState = atom({
  key: "checkoutSessionState", // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});

export const currentCartDataState = atom({
  key: "currentCartDataState", // unique ID (with respect to other atoms/selectors)
  default: {
    total: 0,
    products: [],
  }, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});

export const graphDataState = atom({
  key: "graphDataState",
  default: [
    {
      x: "plane",
      y: 110,
    },
    {
      x: "helicopter",
      y: 28,
    },
    {
      x: "boat",
      y: 261,
    },
    {
      x: "train",
      y: 83,
    },
    {
      x: "subway",
      y: 248,
    },
    {
      x: "bus",
      y: 157,
    },
    {
      x: "car",
      y: 189,
    },
    {
      x: "moto",
      y: 117,
    },
    {
      x: "bicycle",
      y: 14,
    },
    {
      x: "horse",
      y: 116,
    },
    {
      x: "skateboard",
      y: 204,
    },
    {
      x: "others",
      y: 61,
    },
  ],
});

export const useIsLoggedIn = () => {
  const [isInitial, setIsInitial] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);

  useEffect(() => setIsInitial(false), []);

  return [isInitial === true ? false : isLoggedIn, setIsLoggedIn] as const;
};
